import React, { useRef, useEffect } from 'react'
import './participantList.css'
import { BTN_DOWN } from '../../constants/keyboardCode'
import { useDispatch, useSelector } from 'react-redux'
import { openPanel } from '../../redux/room/actionCreator'
import Video from '../Video/Video'

const ParticipantList = () => {
  const buttonRef = useRef(null)
  const dispatch = useDispatch()
  const participants = useSelector(state => state.room.participants)

  useEffect(() => {
    window.addEventListener('keydown', handleButtonPress, false)

    return () => window.removeEventListener('keydown', handleButtonPress, false)
  }, [])

  const handleButtonPress = (event) => {
    if (event.keyCode === BTN_DOWN) {
      buttonRef.current.focus()
    }
  }

  const handlePressButton = () => {
    dispatch(openPanel(false))
  }

  const renderContent = () => {
    if (!participants.length) {
      return (
        <div className='participant_control_container'>
          <h2>Watch with your friends!</h2>
          <p> Enter your mobile number and receive link to start your party</p>
          <div className='participant_control_input-container'>
            <input autoFocus className='participant_control_input' value='(215) 286-3568' type='text' placeholder='(215) 286-3568' />
            <div className='ok_icon' />
          </div>
          <button
            ref={buttonRef}
            className='participant_control_button' onClick={handlePressButton}
          >
            Send a Link
          </button>
        </div>
      )
    } else {
      return (
        <>
          {participants.map(participant => (
            <Video key={participant.participantId} participant={participant} />
          ))}
        </>
      )
    }
  }

  return (
    <>
      {renderContent()}
    </>
  )
}
export default ParticipantList
