import React from 'react'
import './participant.css'
import ParticipantList from '../ParticipantList/ParticipantList'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveTab } from '../../redux/room/actionCreator'
import { tabsList } from '../../constants/tabsList'

const Participant = () => {
  const dispatch = useDispatch()
  const { activeTab, participants } = useSelector(state => state.room)

  return (
    <div className='participant_container'>
      {<div className='block_title small'>{participants.length !== 0 ? `${participants.length} Watching` : ''}</div>}
      {participants.length === 0 && (
        <div className='participant_control'>
          {tabsList.map((tabName, index) => (
            <div
              className={`participant_icon ${tabName} ${activeTab === index ? 'active' : ''}`}
              onClick={() => dispatch(setActiveTab(index))}
              key={index}
            />
          ))}
        </div>
      )}
      <ParticipantList />
    </div>
  )
}

export default Participant
