import React from 'react'
import './emojiPanel.css'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveEmoji } from '../../redux/room/actionCreator'
import { emojiList } from '../../constants/emojiList'

const EmojiPanel = () => {
  const dispatch = useDispatch()
  const { activeEmoji } = useSelector(state => state.room)

  const setActive = (currentIndex) => {
    dispatch(setActiveEmoji(currentIndex))
  }

  return (
    <div className='emoji_container'>
      {emojiList.map((emoji, index) => {
        const classes = index === activeEmoji ? 'emoji_button active-emoji' : 'emoji_button'
        return (
          <div
            onClick={() => setActive(index)}
            key={emoji.code + index} className={classes}
          ><img className='emoji_img' src={emoji.src} />
          </div>
        )
      })}
    </div>
  )
}
export default EmojiPanel
