export const emojiList = [
  {
    code: 128540,
    src: './emoji1.png'
  },
  {
    code: 129315,
    src: './emoji3.png'
  },
  {
    code: 129322,
    src: './emoji4.png'
  },
  {
    code: 129322,
    src: './emoji5.png'
  },
  {
    code: 128540,
    src: './emoji6.png'
  },
  {
    code: 129315,
    src: './emoji7.png'
  },
  {
    code: 129322,
    src: './emoji8.png'
  },
  {
    code: 129322,
    src: './emoji9.png'
  }
]
