import React from 'react'
import './player.css'
import { mainVideo } from '../../constants/videoUrl'

const Player = () => {
  return (
    <div className='main_player_container'>
      <iframe
        title='YouTube video player' className='youtube-player' type='text/html'
        src={mainVideo} frameBorder='0'
        allowFullScreen width='98%'
        allow='autoplay'
      />
    </div>
  )
}
export default Player
