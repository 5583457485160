import React from 'react'
import './mainVide.css'
import Player from '../Player/Player'
import EmojiPanel from '../EmojiPanel/EmojiPanel'
import { useSelector } from 'react-redux'

const MainVideo = () => {
  const { participants } = useSelector(state => state.room)

  return (
    <div className='main_video_container'>
      <div className='block_title'>Watch Party</div>

      <Player />
      <div className='logo_container'>
        <div className='logo_description'>
          <img className='log_image' src='./xfinity_logo.png' />
          <div className='video_description'>
            <div className='video_name'>Every Californians Ever</div>
            <div className='video_info'>2019 * Comedy/SNL</div>
          </div>
        </div>
        {!!participants.length && (<EmojiPanel />)}
      </div>
    </div>
  )
}
export default MainVideo
