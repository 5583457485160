import React, { useEffect, useRef } from 'react'
import './App.css'
import Participant from './components/Participants/Participants'
import MainVideo from './components/MainVideo/MainVideo'
import { BTN_LEFT, BTN_RIGHT, INFO, CHANEL_DOWN, CHANEL_UP } from './constants/keyboardCode'
import authRequest from './HTTP'
import WT from '@sscale/wtsdk'
import { useDispatch, useSelector } from 'react-redux'
import { addParticipant, openPanel, setActiveEmoji, setActiveTab, setParticipants } from './redux/room/actionCreator'
import { tabsList } from './constants/tabsList'

function App () {
  const dispatch = useDispatch()
  const { controlPanel, activeTab, participants, activeEmoji } = useSelector(state => state.room)
  const isShownRef = useRef(controlPanel)
  const participantsRef = useRef(participants)
  const activeTabRef = useRef(activeTab)
  const activeEmojiRef = useRef(activeEmoji)

  useEffect(() => {
    participantsRef.current = participants
  }, [participants])

  useEffect(() => {
    activeTabRef.current = activeTab
  }, [activeTab])
  useEffect(() => {
    activeEmojiRef.current = activeEmoji
  }, [activeEmoji])

  useEffect(() => {
    isShownRef.current = controlPanel
  }, [controlPanel])

  useEffect(() => {
    async function doAuth () {
      const response = await authRequest.post('/token/v2/', {
        room_name: 'anton'
      })

      WT.Session.connectAsAViewer(response.data.streaming_token, 'box_viewer')
    }

    WT.SessionListeners.onConnected((exParticipants) => {
      console.log('exParticipants ', exParticipants)
      // dispatch(setParticipants(exParticipants))
    })

    WT.SessionListeners.onStreamCreated((params) => {
      dispatch(addParticipant(params))
      dispatch(openPanel(true))
    })
    WT.ParticipantListeners.onParticipantLeft((params) => {
      const newParticipants = participantsRef.current.filter(p => p.participantId !== params.participantId)
      dispatch(setParticipants(newParticipants))
    })

    doAuth()
    window.addEventListener('keydown', handleKeydown, false)

    return () => {
      window.removeEventListener('keydown', handleKeydown, false)
    }
  }, [])

  const handleKeydown = (event) => {
    console.log(event.keyCode)
    if (event.keyCode === INFO) {
      dispatch(openPanel(!isShownRef.current))
    }
    if (event.keyCode === BTN_LEFT) {
      if (activeTabRef.current > 0) { dispatch(setActiveTab(activeTabRef.current - 1)) }
    }
    if (event.keyCode === BTN_RIGHT) {
      if (activeTabRef.current < tabsList.length - 1) { dispatch(setActiveTab(activeTabRef.current + 1)) }
    }
    if (event.keyCode === CHANEL_UP) {
      if (activeTabRef.current < tabsList.length - 1) { dispatch(setActiveEmoji(activeEmojiRef.current + 1)) }
    }
    if (event.keyCode === CHANEL_DOWN) {
      if (activeTabRef.current > 0) { dispatch(setActiveEmoji(activeEmojiRef.current - 1)) }
    }
  }

  return (
    <div className='App-container'>
      <div className='Content-container'>
        <MainVideo />
        {controlPanel && (
          <Participant />
        )}
      </div>
    </div>
  )
}

export default App
